import React from 'react';
import { useDispatch } from 'react-redux';
import { signOut } from './AuthReducers';
import { ServerMethodNames } from './methodsRoles';
import { MAX_ROLE_VALUE } from './constants';
import { Channel, CustomSaq, Roles, RolesGroup, WorkflowRole } from '../core/types/api';
import { ReportFilterType } from '../rocTemplates/filtering/reportFilterStorage';
import { ProjectSaqInfo } from '../project/types';
import { useHistory } from '../core/hooks';
import { useAuthContext } from './context';
import { getMeInGroup, isRoleGreater } from './helpers';
//import { useHistory } from 'react-router-dom';

//General API allowed methods mapping for current user
export const AllowedMethodsContext = React.createContext<ServerMethodNames[]>([]);

export function useAllowedMethods(): Record<ServerMethodNames, boolean> {
	const methods = React.useContext(AllowedMethodsContext);
	return methods.reduce((memo: any, curr: string) => ({ ...memo, [curr]: true }), {});
}

//Report related access for current user to map onto responses/comments/etc
type ReportAccessType = {
	isGlobalAdmin: boolean;
	isAdmin: boolean;
	isQSA: boolean;
	isAQSA: boolean;
	isClient: boolean;
	role: number;
	paymentChannels: Channel[];
	customSaqList: CustomSaq[];
	projectSaqInfo: ProjectSaqInfo;
	reportFilter: ReportFilterType;
	workflowRole: WorkflowRole;
};

export const reportAccessInitial: ReportAccessType = {
	isGlobalAdmin: false,
	isAdmin: false,
	isQSA: false,
	isAQSA: false,
	isClient: false,
	role: MAX_ROLE_VALUE,
	paymentChannels: [],
	customSaqList: [],
	projectSaqInfo: { hasAny: false, saq: [], customSaq: [] },
	reportFilter: {},
	workflowRole: WorkflowRole.Customer,
};

export const ReportAccessContext = React.createContext<ReportAccessType>(reportAccessInitial);

export function useReportAccess(): ReportAccessType {
	return React.useContext(ReportAccessContext);
}

//Sign out
export function useSignOut(redirectToLogin?: boolean) {
	const dispatch = useDispatch();
	const history = useHistory();
	return React.useCallback(() => {
		dispatch<any>(signOut());
		if (redirectToLogin) history.push('/login');
	}, [dispatch, history, redirectToLogin]);
}

export function useMyRoleGreaterOrEqual(thanRole?: Roles) {
	const { roles } = useAuthContext();

	return React.useMemo(
		() => (roles ? roles.some((r) => isRoleGreater(r, thanRole, true)) : false),
		[roles, thanRole],
	);
}

export function useMyRoleIncludes(role?: Roles) {
	const { roles } = useAuthContext();

	return React.useMemo(() => !role || roles.some((r) => Number(r) === Number(role)), [role, roles]);
}

export function useMyRoleInGroup(group?: RolesGroup) {
	const { roles } = useAuthContext();

	return React.useMemo(() => getMeInGroup(roles, group), [group, roles]);
}
