import Editor, { PluginEditorProps } from '@draft-js-plugins/editor';
import React from 'react';
import { Box } from '@mui/material';
import createMentionPlugin from '@draft-js-plugins/mention';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import { MentionSuggestionsPubProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/MentionSuggestions';
import { css } from '@emotion/css/macro';
import { mentionDraftPluginConfig } from './constants';
import { draftBlockRenderer, draftDecorators } from './helpers';
import { LinkTooltip } from './Components';

const rootClass = css`
	white-space: pre-wrap;
	word-break: break-word;
`;

function LinkComponent(p) {
	return (
		<LinkTooltip {...p}>
			{/* eslint-disable-next-line jsx-a11y/anchor-has-content -- We get stuff from props */}
			<a {...p} />
		</LinkTooltip>
	);
}

export default function PureDraftEditor({
	mentionsConfig,
	...props
}: PluginEditorProps & { mentionsConfig?: MentionSuggestionsPubProps }) {
	const ref = React.useRef<Editor>(null);

	// Inside a component is the only working way of initializing for mentions to display properly
	const { MentionSuggestions, plugins } = React.useMemo(() => {
		const mentionPlugin = createMentionPlugin(mentionDraftPluginConfig);
		const { MentionSuggestions } = mentionPlugin;
		const plugins = [
			mentionPlugin,
			createLinkifyPlugin({
				component: LinkComponent,
			}),
		];
		return { plugins, MentionSuggestions };
	}, []);

	return (
		<Box onClick={() => ref.current?.focus()} className={rootClass}>
			<Editor
				{...props}
				plugins={plugins}
				decorators={[draftDecorators]}
				blockRendererFn={draftBlockRenderer}
				ref={ref}
			/>
			{mentionsConfig && <MentionSuggestions {...mentionsConfig} />}
		</Box>
	);
}
