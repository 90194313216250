import React from 'react';
import { setInitialDraftEditorState } from './helpers';
import PureDraftEditor from './PureDraftEditor';

type DraftReadOnlyProps = { message?: string };

/**
 * Due to message post-processing (wrapping links with 'a' tags, etc), draft field should always have onChange handler pointing at setState
 */
export default function DraftReadOnly({ message }: DraftReadOnlyProps) {
	const [state, setState] = React.useState(setInitialDraftEditorState(message));
	const handle = React.useCallback((e) => setState(e), []);
	React.useEffect(() => {
		setInitialDraftEditorState(message);
	}, [message]);

	return <PureDraftEditor editorState={state} onChange={handle} readOnly />;
}
