import React from 'react';
import { useFormikContext } from 'formik';
import { CheckboxGroupField } from '../../formaggio';
import { ProjectSubRole, RolesEnum } from '../../auth/enums';
import { subRoleDetails } from '../../auth/constants';
import { GLOBAL_OPTIONS } from '../../core/constants/config';

const getSubRoleOptions = (role: any, isQaEnabled?: boolean) =>
	Object.entries(subRoleDetails)
		.filter(([k]) => {
			if (k === String(ProjectSubRole.QA)) return isQaEnabled;
			if (k === String(ProjectSubRole.Lead)) return role === RolesEnum.Qsa;
			return true;
		})
		.map(([k, v]) => ({
			label: v.title,
			value: Number(k),
		}));

function SubRoleSelector() {
	const formContext = useFormikContext<any>();
	const { role } = formContext.values;

	if (role !== RolesEnum.Qsa && role !== RolesEnum.AssociateQsa) return null;

	return (
		<CheckboxGroupField
			name="projectSubRoles"
			options={getSubRoleOptions(role, GLOBAL_OPTIONS.workflowQAEnabled)}
		/>
	);
}

export default React.memo(SubRoleSelector);
