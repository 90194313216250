import React from 'react';
import { css } from '@emotion/css/macro';
import { isFuture } from 'date-fns';
import { Box, Button } from '@mui/material';
import { Dropdown } from '../../core/components';
import { BasicForm, SelectField, CheckboxField, TextField } from '../../formaggio';
import {
	filterRolesFromOtherRoles,
	getAvailableRoles,
	getMyMaxRole,
	givenRolesToSelectOptions,
} from '../../auth/helpers';
import { spacing } from '../../ui/Core/stylesheets/spacing';
import { ValidationErrors } from '../../formaggio/types';
import { allRolesFlags } from '../../auth/constants';
import { FullUserInfo } from '../components/UsersList';
import { convertToHumanTime } from '../../core/helpers';
import { useAllowedMethods } from '../../auth/hooks';
import { CompanyAssign } from './CompanyAssign';
import { isPasswordSafe } from '../../core/components/PasswordStrength';
import { useAuthContext } from '../../auth/context';
import EditUserInfoForm from './EditUserInfoForm';
import { Roles } from '../../core/types/api';
import { GLOBAL_OPTIONS } from '../../core/constants/config';

export const userDetailsFormClass = css`
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
`;

type NewPasswordFormProps = { newPassword: string; confPassword: string };

const newUserPasswordValidate = (values: NewPasswordFormProps) => {
	const errors: ValidationErrors<NewPasswordFormProps> = {};
	if (values.newPassword !== values.confPassword && !!values.confPassword) {
		errors.confPassword = "Passwords don't match";
	}
	if (!values.newPassword) errors.newPassword = true;
	if (!isPasswordSafe(values.newPassword)) {
		errors.newPassword = 'Password is not safe';
	}
	if (!values.confPassword) errors.confPassword = true;
	return errors;
};

type UserDetailsProps = {
	userDetails: FullUserInfo;
	refresh: () => void;
	setUserDetails: any;
};

export default function UserDetails({ userDetails, refresh, setUserDetails }: UserDetailsProps) {
	const {
		'Company/CreateCompanyUser': canAssignToCompany,
		'Role/AddRoleToUser': canAssignRole,
		'Account/ChangeUserPassword': canEditUser,
		'Account/DeleteUser': canDeleteUser,
	} = useAllowedMethods();

	const { roles: myRoles = [] } = useAuthContext();

	//Role options
	const roleOptions = React.useMemo(() => {
		const userRoles = userDetails.roles || [];
		const availableRoles = getAvailableRoles(allRolesFlags, getMyMaxRole(myRoles));

		return {
			userRolesToRemoveOptions: givenRolesToSelectOptions(
				filterRolesFromOtherRoles(availableRoles, userRoles, true),
			),
			//Remove roles that user already has, and those greater than the editing user
			userRolesToAddOptions: givenRolesToSelectOptions(
				filterRolesFromOtherRoles(availableRoles, userRoles),
			),
		};
	}, [userDetails.roles, myRoles]);

	const mutateRoles = React.useCallback(
		(role: Roles, isRemove = false) =>
			setUserDetails((prev) => ({
				...prev,
				roles: isRemove ? prev.roles.filter((r) => r !== role) : [...prev.roles, role],
			})),
		[setUserDetails],
	);

	return (
		<>
			<Box mb="8px">
				Registered {convertToHumanTime(userDetails.creationDate)}
				<br />
				E-mail: {userDetails.email}
				{userDetails.clientName && (
					<>
						<br />
						Client: {userDetails.clientName}
					</>
				)}
			</Box>
			<section>
				{canEditUser && (
					<>
						<Dropdown header="Edit User">
							<EditUserInfoForm onSuccess={refresh} userDetails={userDetails} />
						</Dropdown>
						<Dropdown header="Change password">
							<BasicForm
								fetchConfig={{
									query: 'account/ChangeUserPassword',
									method: 'PUT',
									successMessage: true,
									confirmation: true,
									onSuccess: () => refresh(),
								}}
								additional={{ userId: userDetails.id }}
								validate={newUserPasswordValidate}
							>
								<TextField
									InputProps={{ autoComplete: 'new-password' }}
									label="New password"
									name="newPassword"
									type="password"
									isNewPasswordField
								/>
								<TextField
									InputProps={{ autoComplete: 'new-password' }}
									label="Confirm"
									name="confPassword"
									type="password"
								/>
								<Button type="submit" color="primary">
									Set
								</Button>
							</BasicForm>
						</Dropdown>
						{userDetails?.lockoutEnd && isFuture(new Date(userDetails.lockoutEnd)) && (
							<Dropdown header="Unlock user">
								<BasicForm
									fetchConfig={{
										query: 'account/EditUserSetLockoutEnd',
										method: 'POST',
										successMessage: true,
										confirmation: true,
										onSuccess: () => refresh(),
										qs: { userId: userDetails.id },
									}}
								>
									<p>Typically a lock is applied after too many unsuccessful login attempts</p>
									<Button type="submit" color="primary">
										Unlock
									</Button>
								</BasicForm>
							</Dropdown>
						)}
						<Dropdown header="Enable/Disable user">
							<BasicForm
								fetchConfig={{
									query: 'account/SetUserEnabled',
									method: 'PUT',
									successMessage: true,
									confirmation: true,
									noResetOnSuccess: true,
									onSuccess: () => refresh(),
								}}
								initialValues={{ userId: userDetails.id, isEnabled: userDetails.active }}
								noResetOnSuccess
							>
								<CheckboxField label="User enabled" name="isEnabled" />
								<Button type="submit" color="secondary">
									Set
								</Button>
							</BasicForm>
						</Dropdown>
						{userDetails.twoFactorEnabled && (
							<Dropdown header="Reset 2FA">
								<BasicForm
									fetchConfig={{
										query: 'account/ResetUserAuthenticator',
										qs: { userId: userDetails.id },
										method: 'PUT',
										successMessage: true,
										confirmation: true,
										onSuccess: () => refresh(),
									}}
									initialValues={{ userId: userDetails.id, isEnabled: userDetails.active }}
									noResetOnSuccess
								>
									{GLOBAL_OPTIONS.forceTwoFactorAuth && (
										<p>
											Warning: After resetting next user login will be followed up with 2FA setup
											dialog
										</p>
									)}
									<Button type="submit" color="error" variant="contained">
										Reset
									</Button>
								</BasicForm>
							</Dropdown>
						)}
					</>
				)}
				<>
					{canAssignToCompany && <CompanyAssign refresh={refresh} userDetails={userDetails} />}
					{canAssignRole && (
						<Dropdown header="Add/remove global role">
							<BasicForm
								fetchConfig={{
									query: 'role/AddRoleToUser',
									method: 'PUT',
									successMessage: true,
									onSuccess: () => refresh(),
									handleValuesOnSuccess: (values) => mutateRoles(values.role),
								}}
								additional={{ userName: userDetails.userName }}
							>
								<SelectField label="Role" name="role" options={roleOptions.userRolesToAddOptions} />
								<Button type="submit" color="primary">
									Add
								</Button>
							</BasicForm>
							<BasicForm
								fetchConfig={{
									query: 'role/RemoveRoleFromUser',
									method: 'DELETE',
									successMessage: true,
									confirmation: true,
									onSuccess: () => refresh(),
									handleValuesOnSuccess: (values) => mutateRoles(values.role, true),
								}}
								additional={{ userName: userDetails.userName }}
							>
								<SelectField
									label="Role"
									name="role"
									options={roleOptions.userRolesToRemoveOptions}
								/>
								<Button type="submit" color="secondary">
									Remove
								</Button>
							</BasicForm>
						</Dropdown>
					)}
					{canDeleteUser && (
						<Dropdown header="Remove user">
							<BasicForm
								fetchConfig={{
									query: 'account/DeleteUser',
									method: 'DELETE',
									successMessage: true,
									confirmation: true,
									onSuccess: () => refresh(),
								}}
								additional={{ userName: userDetails.userName }}
								className={css`
									margin-top: ${spacing.small};
								`}
							>
								<Button style={{ color: 'white', background: 'firebrick' }} type="submit">
									DELETE USER
								</Button>
							</BasicForm>
						</Dropdown>
					)}
				</>
			</section>
		</>
	);
}
