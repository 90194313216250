import { LicenseStatus } from '../hubs/LicenseStatus';
import { FeatureDetail } from '../core/types/api';
import { useLicenseStatus } from '../hubs/hooks';

export enum FeatureFlag {
	DowngradePci321r2To321r1 = 'downgrade.pci.321r2_to_321r1',
	DowngradePci40r1r2To40r1 = 'downgrade.pci.40r1r2_to_40r1',
	PciScopeBuilder = 'pci.scope.builder',
	ProjectFullClone = 'project.full_clone',
	WorkflowMoveToQSA = 'workflow.move_to_qsa',
	CompanyCreateMultiple = 'company.create_multiple',
}

export type FeatureFlagDetails = Partial<Record<string, FeatureDetail>>;

export function isFeatureEnabled(license: LicenseStatus, feature: FeatureFlag) {
	return license && license.features && license.features.indexOf(String(feature)) >= 0;
}

export function useFeatureEnabled(feature: FeatureFlag) {
	const license = useLicenseStatus();

	return license && license.features && license.features.indexOf(String(feature)) >= 0;
}

export function useFeatureEnabledScopeBuilder() {
	return useFeatureEnabled(FeatureFlag.PciScopeBuilder);
}

export function useFeatureEnabledProjectFullClone() {
	return useFeatureEnabled(FeatureFlag.ProjectFullClone);
}

export function useFeatureEnableCompanyCreateMultiple() {
	return useFeatureEnabled(FeatureFlag.CompanyCreateMultiple);
}
