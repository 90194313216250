import { css } from '@emotion/css/macro';
import React from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { AutoStories, ContactMail, Key, PersonAdd, Search } from '@mui/icons-material';
import { isFuture } from 'date-fns';
import { Heading, Button, Pagination, UserTypeBadge } from '../../core/components';
import UserDetails from '../UserDetails';
import { spacing } from '../../ui/Core/stylesheets/spacing';
import { Roles, UserInfo, UserInfoSuccessResponse } from '../../core/types/api';
import { useHistory, useHistoryPush, useToggle } from '../../core/hooks';
import Registration from './Registration';
import { phrases } from '../../core/constants/phrases';
import MaterialFlyOut from '../../flyout/components/MaterialFlyOut';
import { useAllowedMethods, useMyRoleGreaterOrEqual } from '../../auth/hooks';
import { getMyMaxRole } from '../../auth/helpers';
import SendInvitationFlyOut from './SendInvitation';
import UserSearchFilter from './UserSearchFilter';
import { useUserSearch } from '../hooks';
import { convertToHumanTime } from '../../core/helpers';
import { error, mainLight, warning } from '../../ui/Core/stylesheets/colors';
import IsaBadge from '../../contact/IsaBadge';
import ShortUserInfo from '../../core/components/ShortUserInfo';

export type FullUserInfo = UserInfoSuccessResponse & UserInfo;

export const filtersClass = css`
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	& > div,
	& > button {
		margin-right: ${spacing.medium};
	}
`;

const buttonClass = css`
	margin-bottom: 1rem !important;
`;

const accountInfoStyleClass = css`
	list-style: square;
`;

function AccountInfoItemMarked({ color, children }: { color?: string; children: React.ReactNode }) {
	return (
		<li
			className={css`
				&::marker {
					color: ${color || mainLight};
				}
			`}
		>
			{children}
		</li>
	);
}

type UserRowProps = {
	user: FullUserInfo;
	onShowDetails: (user: FullUserInfo) => void;
};

function UserRow({ user, onShowDetails }: UserRowProps) {
	const history = useHistory();
	const showAuditLog = React.useCallback(
		() => history.push(`/portal/audit-log/main?authorId=${user.id}`),
		[history, user.id],
	);

	const showUserDetails = React.useCallback(() => onShowDetails(user), [onShowDetails, user]);

	const userRoles = user?.roles;
	const userMaxRole = getMyMaxRole(user.roles);

	const renderBadges = React.useMemo(
		() =>
			userRoles?.map((role: number, idx: number) => (
				<UserTypeBadge userRole={role} key={`${role + idx}`} />
			)),
		[userRoles],
	);

	const companies = user?.company;
	const renderCompanies = React.useMemo(
		() => companies?.map(({ companyName }, idx) => <div key={idx}>{companyName}</div>),
		[companies],
	);
	const isMyRoleGreaterThanUser = useMyRoleGreaterOrEqual(userMaxRole);

	return (
		<TableRow>
			<TableCell>
				<ShortUserInfo {...user} />
			</TableCell>
			<TableCell>{renderCompanies}</TableCell>
			<TableCell>
				{renderBadges}
				{user.userClient?.isISA && <IsaBadge />}
			</TableCell>
			<TableCell>
				<ul className={accountInfoStyleClass}>
					<AccountInfoItemMarked color={!user.active && warning}>
						Account {user.active ? 'enabled' : 'disabled'}
					</AccountInfoItemMarked>
					<AccountInfoItemMarked color={!user.emailConfirmed && warning}>
						Email {user.emailConfirmed ? 'confirmed' : 'not confirmed'}
					</AccountInfoItemMarked>
					<AccountInfoItemMarked color={!user.twoFactorEnabled && error}>
						2FA {user.twoFactorEnabled ? 'enabled' : 'not enabled'}
					</AccountInfoItemMarked>
					{user.lockoutEnd && isFuture(new Date(user.lockoutEnd)) && (
						<AccountInfoItemMarked color={error}>
							Locked out until {convertToHumanTime(user.lockoutEnd)}
						</AccountInfoItemMarked>
					)}
				</ul>
			</TableCell>
			<TableCell>
				{isMyRoleGreaterThanUser && (
					<Button startIcon={<Search />} size="small" onClick={showUserDetails} color="primary">
						Details
					</Button>
				)}
			</TableCell>
			<TableCell>
				<Button startIcon={<AutoStories />} color="secondary" size="small" onClick={showAuditLog}>
					Audit
				</Button>
			</TableCell>
		</TableRow>
	);
}

export default function Users() {
	const { list, pagesCount, search, selectPage, reset, refresh, page } = useUserSearch();
	const iAmMasterAdmin = useMyRoleGreaterOrEqual(Roles.MasterAdministrator);

	const {
		'Account/CreateUser': canRegisterUser,
		'Project/SendInvitation': canInviteUser,
		'Account/ViewUsers': canViewUsers,
		'CompanyToken/List': canViewTokens,
	} = useAllowedMethods();

	const [userDetails, setUserDetails] = React.useState<any>(null);

	const { isOn: isUserRegOn, toggleOff: userRegOff, toggleOn: userRegOn } = useToggle();
	const { isOn: isUserInviteOn, toggleOff: userInviteOff, toggleOn: userInviteOn } = useToggle();

	const closeAllAndRefresh = React.useCallback(() => {
		userRegOff();
		userInviteOff();
		setUserDetails(null);
		if (canViewUsers) refresh();
	}, [userRegOff, userInviteOff, canViewUsers, refresh]);

	const userDetailsFlyOutTitle =
		userDetails?.firstName && userDetails?.lastName
			? `${userDetails.firstName} ${userDetails.lastName}`
			: userDetails?.userName || phrases.unnamedUser;

	const renderUserRows = React.useCallback(
		(user: FullUserInfo, idx) => (
			<UserRow key={`${user.id}${idx}`} user={user} onShowDetails={setUserDetails} />
		),
		[setUserDetails],
	);

	const showInvitations = useHistoryPush('/portal/tokens');

	return (
		<>
			<ButtonGroup color="secondary" variant="outlined">
				{canInviteUser && (
					<Button startIcon={<ContactMail />} onClick={userInviteOn} className={buttonClass}>
						Invite new user
					</Button>
				)}
				{canViewTokens && (
					<Button startIcon={<Key />} onClick={showInvitations} className={buttonClass}>
						View tokens
					</Button>
				)}
				{canRegisterUser && (
					<Button startIcon={<PersonAdd />} onClick={userRegOn} className={buttonClass}>
						Create user
					</Button>
				)}
			</ButtonGroup>
			{canViewUsers && (
				<UserSearchFilter
					hasClientSelector
					hasCompanySelector={iAmMasterAdmin}
					search={search}
					reset={reset}
				/>
			)}
			{list && (
				<>
					{list.length > 0 ? (
						<>
							<Pagination
								buttonsCount={10}
								pagesCount={pagesCount}
								selectPage={selectPage}
								page={page}
							/>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell>Username</TableCell>
										<TableCell>QSA Company</TableCell>
										<TableCell>Role(s)</TableCell>
										<TableCell colSpan={3}>Status</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>{list.map(renderUserRows)}</TableBody>
							</Table>
						</>
					) : (
						<Heading size={3}>No users found</Heading>
					)}
				</>
			)}
			<MaterialFlyOut open={isUserRegOn} onClose={closeAllAndRefresh} title="Create User">
				<Registration
					isAdmin
					onSuccess={closeAllAndRefresh}
					formQuery="account/CreateUser"
					showUserNameField
				/>
			</MaterialFlyOut>
			<SendInvitationFlyOut isOn={isUserInviteOn} close={closeAllAndRefresh} />
			{!!userDetails && (
				<MaterialFlyOut
					open={!!userDetails}
					onClose={closeAllAndRefresh}
					title={userDetailsFlyOutTitle}
				>
					<UserDetails
						refresh={refresh}
						userDetails={userDetails}
						setUserDetails={setUserDetails}
					/>
				</MaterialFlyOut>
			)}
		</>
	);
}
