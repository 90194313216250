import { jwtDecode } from 'jwt-decode';
import { fromUnixTime } from 'date-fns';
import { AUTH_TOKEN_KEY, roleTitles } from './constants';
import methodsRoles, { ServerMethodNames } from './methodsRoles';
import { RouteType } from '../../features/Portal/pages/Portal';
import { ProjectSubRole, RolesEnum } from './enums';
import { hasAny, hasFlag } from '../core/helpers';
import { Roles, RolesGroup } from '../core/types/api';
import { CONFIG } from '../core/constants/config';

export function isAuthenticated() {
	return !!localStorage.getItem(AUTH_TOKEN_KEY);
}

export function getTokenTime() {
	const jwt: any = jwtDecode(localStorage.getItem(AUTH_TOKEN_KEY) || '');
	return { started: fromUnixTime(jwt.nbf) || '', expires: fromUnixTime(jwt.exp) || '' };
}

export function getRoleHierarchy(role?: Roles): number | undefined {
	return role ? CONFIG.roleHierarchy[role] : undefined;
}

export function getRolesHierarchy(roles?: Roles[]): number | undefined {
	return roles ? Math.min(...roles.map((r) => getRoleHierarchy(r))) : undefined;
}

export function isRoleGreater(role?: Roles, thanRole?: Roles, orEqual?: boolean): boolean {
	if (!role) return false;
	if (!thanRole) return true;

	const rh = getRoleHierarchy(role);
	const trh = getRoleHierarchy(thanRole);

	return orEqual ? rh <= trh : rh < trh;
}

// TODO: Consider hierarchy
export function getMyMaxRole(roles?: number[]) {
	return Math.min(...(roles || []));
}

export function getMeInGroup(roles?: number[], group?: RolesGroup): boolean {
	return roles && group ? roles.some((myRole) => hasFlag(CONFIG.roleGroups[group], myRole)) : false;
}

export function givenRolesToSelectOptions(roles: number[], isValueRoleName?: boolean) {
	return roles.map((role: number) => ({
		value: isValueRoleName ? RolesEnum[role] : role,
		title: roleTitles[role],
	}));
}

export function getAvailableRoles(roles: number[], myGreatestRole?: number) {
	if (!myGreatestRole) return [];

	return roles.filter((value) => value >= myGreatestRole);
}

function filteredAllowedMethods(methods: ServerMethodNames[], userRoles?: number[]) {
	if (!userRoles) return [];

	return methods.reduce<any>((memo, methodName) => {
		const methodRoles = methodsRoles[methodName];

		if (!methodRoles) return memo;

		return methodRoles.some((methodRole) => userRoles.indexOf(methodRole) >= 0) ||
			methodRoles.length === 0
			? [...memo, methodName] // Add to list if method is allowed
			: memo;
	}, []);
}

export function reduceRoleRoutes(memo: object[], currentRoute: RouteType, userRoles?: number[]) {
	const { usedMethods } = currentRoute;
	if (!userRoles || usedMethods.length === 0) return memo;

	const allowedMethods = filteredAllowedMethods(usedMethods, userRoles);

	if (allowedMethods.length === 0 || hasAny(userRoles || [], currentRoute.hideFromRoles || []))
		return memo;

	const finalRoute = currentRoute;
	if (currentRoute.path === '/portal/companies') {
		const title =
			getMyMaxRole(userRoles) >= RolesEnum.ClientAdministrator ? 'Company' : 'Companies';
		finalRoute.title = title;
		if (finalRoute.sidebarItem) {
			finalRoute.sidebarItem.label = title;
		}
	}

	return [...memo, { ...finalRoute, allowedMethods }];
}

/**
 * Takes list 'roles' and removes 'otherRoles' from it
 * @param roles
 * @param otherRoles
 * @param isOppositeFilter
 */
export function filterRolesFromOtherRoles(
	roles: number[],
	otherRoles: number[],
	isOppositeFilter?: boolean,
): number[] {
	const originalRoles = [...roles];
	return originalRoles.filter((currentRole: number) => {
		const hasRole = otherRoles.indexOf(currentRole) >= 0;
		return isOppositeFilter ? hasRole : !hasRole;
	});
}

export function isClientRole(roles: number[] = []) {
	return (
		roles.indexOf(RolesEnum.Client) >= 0 ||
		roles.indexOf(RolesEnum.ExecutiveClientRepresentative) >= 0
	);
}

export function getProjectSubRoleNames(subRoles = 0) {
	const result = [];

	if (hasFlag(subRoles, ProjectSubRole.Lead)) result.push('Lead');
	if (hasFlag(subRoles, ProjectSubRole.Reviewer)) result.push('Reviewer');
	if (hasFlag(subRoles, ProjectSubRole.QA)) result.push('QA');

	return result.join(', ');
}

export function getSubRolesByRole(role: Roles, isQaEnabled?: boolean): number {
	if (role !== Roles.Qsa) return 0;

	return ProjectSubRole.Lead + ProjectSubRole.Reviewer + (isQaEnabled ? ProjectSubRole.QA : 0);
}
