import React from 'react';
import { css } from '@emotion/css/macro';
import { Tooltip } from '@mui/material';
import { showDocument } from './helpers';
import SimpleLink from '../../components/SimpleLink';

export function LinkTooltip({ children, ...props }) {
	return (
		<Tooltip
			leaveDelay={1000}
			title={
				<a {...props} target="_blank" rel="noopener noreferrer">
					Open link
				</a>
			}
		>
			{children}
		</Tooltip>
	);
}

export function DraftTitledLink({ contentState, entityKey, children }: any) {
	const { url } = contentState.getEntity(entityKey).getData();
	return (
		<LinkTooltip href={url}>
			<a href={url}>{children}</a>
		</LinkTooltip>
	);
}

const imageClass = css`
	max-width: 400px;
	max-height: 300px;
	padding: 4px;
	cursor: pointer;
`;

export function DraftAtomic({ contentState, block }: any) {
	const entityAt = block.getEntityAt(0);

	if (!entityAt) {
		return <>Image render error!</>;
	}

	const entity = contentState.getEntity(entityAt);
	const { src = '' } = entity.getData();
	const type = entity.getType();

	// eslint-disable-next-line react-hooks/rules-of-hooks -- Debug
	const image = React.useMemo(() => {
		const isBase = src.startsWith('data:');
		const clickHandler = isBase ? () => showDocument(src) : () => undefined;

		return {
			isBase,
			component: (
				<img className={imageClass} alt="" src={src} onClick={clickHandler} role="presentation" />
			),
		};
	}, [src]);

	return type === 'IMAGE' ? (
		<div>
			{image.isBase ? (
				image.component
			) : (
				<SimpleLink href={src} target="_blank">
					{image.component}
				</SimpleLink>
			)}
		</div>
	) : (
		<div />
	);
}
