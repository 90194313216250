import React from 'react';
import { UserInfoSuccessResponse } from '../../graphql/typescript';

type AuthContextType = UserInfoSuccessResponse;

const AuthContext = React.createContext<AuthContextType>({});

export function useAuthContext(): AuthContextType {
	return React.useContext(AuthContext);
}

type AuthContextWrapperProps = { children: React.ReactNode; userInfo: UserInfoSuccessResponse };

export function AuthContextWrapper({ children, userInfo = {} }: AuthContextWrapperProps) {
	const [info, setInfo] = React.useState<AuthContextType>({
		...userInfo,
	});

	React.useEffect(() => {
		setInfo({ ...userInfo });
	}, [userInfo]);

	return <AuthContext.Provider value={info}>{children}</AuthContext.Provider>;
}
